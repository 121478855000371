/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoHeaderDesktop from '../../Resources/Media/Common/Logos/logo-header-desktop.svg';

import arrowUp from '../../Resources/Media/Incentive/Icons/arrow-up.svg';

import './_header.scss';

import MenuMobile from '../../Resources/Media/Common/menu-mobile.svg';

import CloseMenuMobile from '../../Resources/Media/Common/close-menu-mobile.svg';
import { LoadingContext } from '../../Shared/LoadingContext';
import useHandlersHeader from './hooks/useHandlersHeader';
import { GetPoints } from '../../Backend/getPoints';
import { FaSpinner } from 'react-icons/fa';
import { PopupContext } from '../../Shared/PopupContext';
import { GetPopup } from '../../Backend/popup';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [points, setPoints] = useState(null);
  const [viewPoints, setViewPoints] = useState(true);
  const [expires, setExpires] = useState(null);
  const menuRef = useRef(null);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const { setLoading } = useContext(LoadingContext);
  const { setPopupData } = useContext(PopupContext);

  const getPopupData = async () => {
    const response = await GetPopup();

    if (response.success) {
      setPopupData(response.data);
    }
  };

  useEffect(() => {
    getPopupData();
  }, []);

  const {
    toggleMenu,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleClickOutside,
    scrollIntoHome,
    handleLogout,
  } = useHandlersHeader({ setIsOpen, isOpen, touchStartX, touchEndX, menuRef });

  const navigate = useNavigate();

  const location = useLocation();

  const { hash } = location;

  const name = localStorage.getItem('name');

  const isLogged = localStorage.getItem('isLogged');

  const getPoints = async () => {
    const response = await GetPoints();
    if (response.success) {
      setPoints(response.data.points);
      
      if(response.data.expires !== null) {
        setExpires(response.data.expires);
      }

      if(response.data.points === null) {
        setViewPoints(false);
      }

      localStorage.setItem('points', response.data.points);
    }
  };

  useEffect(() => {
    getPoints();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (hash === '#award') {
        scrollIntoHome('award');
      } else if (hash === '#participate') {
        scrollIntoHome('participate');
      } else if (hash === '') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const delay = 500;
    const timerId = setTimeout(() => {
      handleScroll();
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [hash]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      var header = document.getElementsByClassName('header-desktop')[0];

      if (scrollTop > header?.getBoundingClientRect()?.top) {
        header.classList.add('header-desktop__onScroll');
      } else {
        // header.classList.remove('header-desktop__onScroll');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isLogged) {
      navigate('/entrar');
    }
  }, [isLogged]);

  return (
    <>
      <header className="header-desktop header-desktop__offScroll justify-center w-full hidden lg:flex header-desktop__onScroll">
        <div className="header-desktop__img-desktop flex justify-center">
          <img
            className="!mt-[-45px] !h-[135px] !max-w-[none]"
            src={LogoHeaderDesktop}
            alt="Neo Pharma - Excelência"
          />
        </div>
        <div className="header-desktop__items flex justify-between w-full items-start mt-[30px]">
          <Link
            to="/"
            className={`${location.pathname === '/' ? '!text-[#ffd800]' : ''}`}
            onClick={() => {
              if (location.pathname !== '/') {
                setLoading(true);
              }
            }}
          >
            HOME
          </Link>
          <Link
            className={
              location.pathname === '/como-pontuar' ? '!text-[#ffd800]' : ''
            }
            to="/como-pontuar"
            onClick={() => {
              if (location.pathname !== '/como-pontuar') {
                setLoading(true);
              }
            }}
          >
            COMO PONTUAR
          </Link>

          <Link
            className={
              location.pathname === '/resultados' ? '!text-[#ffd800]' : ''
            }
            to="/resultados"
            onClick={() => {
              if (location.pathname !== '/resultados') {
                setLoading(true);
              }
            }}
          >
            RESULTADOS
          </Link>
          <Link
            className={
              location.pathname === '/premiacoes' ? '!text-[#ffd800]' : ''
            }
            to="/premiacoes"
            onClick={() => {
              if (location.pathname !== '/premiacoes') {
                setLoading(true);
              }
            }}
          >
            PREMIAÇÕES
          </Link>
          <Link
            className={location.pathname === '/blog' ? '!text-[#ffd800]' : ''}
            to="/blog"
            onClick={() => {
              if (location.pathname !== '/blog') {
                setLoading(true);
              }
            }}
          >
            BLOG
          </Link>
          <div className="!bg-[#ffd800] h-[37px] mt-[-4px] hover:!bg-white rounded-[100px] w-[140px] flex items-center justify-center">
            <Link
              to="/aceleradores"
              className="!bg-transparent !text-[#031e78] mt-[4px]"
              onClick={() => {
                if (location.pathname !== '/aceleradores') {
                  setLoading(true);
                }
              }}
            >
              ACELERADORES
            </Link>
          </div>
          <div className="header-desktop__last-items relative flex flex-col gap-2 justify-end">
            <div
              className="header-desktop__last-items__hello-card absolute top-[47px] flex justify-between hover:cursor-pointer"
              onClick={() => setOpenDetail(!openDetail)}
            >
              Olá, {name}{' '}
              <img
                src={arrowUp}
                alt=""
                className={`${openDetail ? '' : 'rotate-180'} duration-[200ms]`}
                on
              />
              {openDetail && (
                <div className="!w-[135px] left-0 absolute header-desktop__last-items__hello-card__details flex flex-col gap-4 top-[27px]">
                  <ul>
                    <li></li>
                    <li>
                      <Link
                        to="/meu-perfil"
                        className="leading-[1] link-dropdown"
                        onClick={() => {
                          if (location.pathname !== '/meu-perfil') {
                            setLoading(true);
                          }
                        }}
                      >
                        Meu perfil
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link-dropdown"
                        to="/gerenciar-farmacias"
                        onClick={() => {
                          if (location.pathname !== '/gerenciar-farmacias') {
                            setLoading(true);
                          }
                        }}
                      >
                        Gerenciar
                        <br />
                        Farmácias
                      </Link>
                    </li>
                    <li></li>
                    <li>
                      <Link
                        className="link-dropdown"
                        onClick={() => handleLogout()}
                      >
                        Sair
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {viewPoints === true &&
            <div className="header-desktop__portal-points !w-[196px] rounded-[100px] flex items-center justify-center">
              {points !== null ? (
                <p className="leading-[1] text-center text-[14px]">
                  Você tem<span> {points === '-' ? '-' : points} pontos.</span>
                  {expires !== null &&
                    <div className="text-red-500 text-[13px] font-medium">{expires} pontos a expirar.</div>
                  }
                </p>
              ) : (
                <FaSpinner className="animate-spin mr-2" />
              )}
            </div>
            }
          </div>
        </div>
      </header>

      <header className="header lg:hidden">
        <div className="header-wrapper">
          <div className="header-logo">
            <Link
              to="/"
              onClick={() => {
                if (location.pathname !== '/') {
                  setLoading(true);
                }
              }}
            >
              <img
                className="logo-header top-[-32px] !h-[118px] absolute left-[1px]"
                src={LogoHeaderDesktop}
                alt="Vitaminas Neo Química"
              />
            </Link>
          </div>

          <div
            className={`header-menu ${isOpen ? 'open' : ''}`}
            ref={menuRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className={`header-menu-toggle ${isOpen ? 'open' : ''}`}
              onClick={toggleMenu}
            >
              <img
                src={isOpen ? CloseMenuMobile : MenuMobile}
                className="lg:hidden"
                alt=""
              />
            </div>

            <div className={`header-menu-items ${isOpen ? 'open' : ''}`}>
              <div className="header-menu__select-container">
                <div className="header__select-container__logo">
                  <Link
                    to="/"
                    onClick={() => {
                      if (location.pathname !== '/') {
                        setLoading(true);
                      }
                    }}
                  >
                    <img
                      className="logo-header"
                      src={LogoHeaderDesktop}
                      alt="Vitaminas Neo Química"
                    />
                  </Link>
                </div>
                <div
                  className="flex justify-between items-center h-[39px] absolute w-[230px] top-[140px] z-[99999] hover:cursor-pointer"
                  onClick={() => setOpenDetail(!openDetail)}
                >
                  <p style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>
                    Olá, {name}
                  </p>
                  <img
                    src={arrowUp}
                    alt=""
                    className={`${
                      openDetail ? '' : 'rotate-180'
                    } duration-[200ms]`}
                  />
                </div>
                <div
                  className={`header-menu__select-container__collapse absolute top-[70px] leading-none  ${
                    openDetail
                      ? 'w-full pl-[46.5px] pr-[46.5px] !h-[200%] items-start h-full'
                      : 'items-center h-[39px]'
                  }`}
                >
                  {openDetail ? (
                    <div className="flex flex-col items-start mt-10 gap-5">
                      {viewPoints === true &&
                      <p>
                        Você tem <br />
                        <span>{points === '-' ? '0' : points} pontos.</span>
                        {expires !== null &&
                          <div className="text-red-500">{expires} pontos<br /> a expirar.</div>
                        }
                      </p>
                      }
                      <Link
                        to="/meu-perfil"
                        onClick={() => {
                          if (location.pathname !== '/meu-perfil') {
                            setLoading(true);
                          }
                        }}
                      >
                        Meu perfil
                      </Link>
                      <Link
                        to="/gerenciar-farmacias"
                        onClick={() => {
                          if (location.pathname !== '/gerenciar-farmacias') {
                            setLoading(true);
                          }
                        }}
                      >
                        Gerenciar
                        <br />
                        Farmácias
                      </Link>
                      <Link onClick={() => handleLogout()}>Sair</Link>
                    </div>
                  ) : undefined}
                </div>
              </div>

              <nav>
                <ul>
                  <li
                    className={location.pathname === '/' ? 'selected-link' : ''}
                  >
                    <Link
                      to="/"
                      onClick={() => {
                        toggleMenu();
                        if (location.pathname !== '/') {
                          setLoading(true);
                        }
                      }}
                    >
                      HOME
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/como-pontuar'
                        ? 'selected-link'
                        : ''
                    }
                  >
                    <Link
                      to="/como-pontuar"
                      onClick={() => {
                        toggleMenu();
                        if (location.pathname !== '/como-pontuar') {
                          setLoading(true);
                        }
                      }}
                    >
                      COMO PONTUAR
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/aceleradores'
                        ? 'selected-link'
                        : ''
                    }
                  >
                    <Link
                      to="/aceleradores"
                      onClick={() => {
                        toggleMenu();
                        if (location.pathname !== '/aceleradores') {
                          setLoading(true);
                        }
                      }}
                    >
                      ACELERADORES
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/resultados' ? 'selected-link' : ''
                    }
                  >
                    <Link
                      to="/resultados"
                      onClick={() => {
                        toggleMenu();
                        if (location.pathname !== '/resultados') {
                          setLoading(true);
                        }
                      }}
                    >
                      RESULTADOS
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/premiacoes' ? 'selected-link' : ''
                    }
                  >
                    <Link
                      to="/premiacoes"
                      onClick={() => {
                        toggleMenu();
                        if (location.pathname !== '/premiacoes') {
                          setLoading(true);
                        }
                      }}
                    >
                      PREMIAÇÕES
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/blog' ? 'selected-link' : ''
                    }
                  >
                    <Link
                      to="/blog"
                      onClick={() => {
                        toggleMenu();
                        if (location.pathname !== '/blog') {
                          setLoading(true);
                        }
                      }}
                    >
                      BLOG
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/resultados"
                      onClick={() => {
                        toggleMenu();
                        setLoading(true);
                      }}
                    >
                      FALE CONOSCO
                    </Link>
                  </li> */}
                  <li
                    className={
                      location.pathname === '/resultados' ? 'selected-link' : ''
                    }
                  >
                    <Link
                      to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Neopharma_Excelencia.pdf`}
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      REGULAMENTO
                    </Link>
                  </li>
                </ul>
              </nav>
              <p>© Copyright Neo Química</p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
