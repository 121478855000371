import { useContext } from 'react';
import { LoadingContext } from '../../../../../../Shared/LoadingContext';
import { useNavigate } from 'react-router-dom';

const useControlErrors = ({
  setError,
  setErrorOutForm,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const setErrorInFields = (response) => {
    const errors = response.data.errors;

    if (!errors) {
      setErrorOutForm(response.data.message);

      return;
    }
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        setError(field, {
          type: 'manual',
          message: errors[field][0],
        });

        setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      }
    }

    setLoading(false);
  };

  const verifyErrors = (response) => {
    const userHasMoreThanOneStore = response?.data?.stores?.length > 1;
    const userHasOneStore = response?.data?.stores?.length === 1;
    const userHasZeroStore = response?.data?.stores?.length === 0;

    if (response.data.user && userHasMoreThanOneStore) {
      navigate('/bem-vindo', {
        state: {
          stores: response.data.stores,
          user: response.data.user,
          step: 3,
        },
      });

      return 'page_seted';
    } else if (response.data.user && userHasOneStore) {
      navigate('/bem-vindo', {
        state: {
          stores: response.data.stores,
          user: response.data.user,
          step: 1,
        },
      });
      return 'page_seted';
    } else if (response.data.user && userHasZeroStore) {
      navigate('/bem-vindo', {
        state: {
          stores: [],
          user: response.data.user,
          email: response.data.email,
          step: 5,
        },
      });
      return 'page_seted';
    }
  };

  const verifyErrorResultAndSetPage = (response) => {
    const pageSeted = verifyErrors(response);

    setLoading(false);

    if (pageSeted) {
      setLoading(false);
    } else {
      setErrorInFields(response);
    }
  };

  const setUserLogged = async (response) => {
    navigate('/');
    setLoading(false);
    localStorage.setItem('isLogged', 'logged');
  };

  return { verifyErrorResultAndSetPage, setUserLogged };
};

export default useControlErrors;
